//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonForm from "@/components/common/CommonForm";
import LazyFormRow from "@/components/common/LazyFormRow/index";
import popupWithForm from "@/mixins/popupWithForm";
import customerDataEvent from "~/assets/js/eSputnik/customerDataEvent";
import {mapGetters} from "vuex";
import FormRecaptca from "~/components/common/LazyFormRow/components/components/FormRecaptca.vue";

export default {
    name: "SubscribeForm",
    mixins: [popupWithForm],
    components: {FormRecaptca, LazyFormRow, CommonForm},
    props: {
        source: {
            type: String,
            default: 'pop_up'
        }
    },
    methods: {
        successSubmit(result) {
            this.$ga.sendSimpleEvent('popup-subscription')
            let step = result.SubscriptionForm.code === 304 ? 3 : 2;

            let userData = {
                email: this.keyValueFormData.email
            };
            if (this.userInfo) {
                userData.firstName = _get(this, 'userInfo.firstName', false);
                userData.phoneNumber = _get(this, 'userInfo.phoneNumber', false);
            }
            customerDataEvent(userData);
            this.$emit('changeStep', {
                step: step,
                message: result.SubscriptionForm.message
            })
            sessionStorage.setItem('canShowSubscribe', 2);
        },
        async errorSubmit(error) {
            if (error?.SubscriptionForm?.token) {
                this.hasCaptchaError = true;
                this.$store.commit(`forms/${ types.UPDATE_FORM_VALUE_BY_ID }`, {
                    formId: this.formId,
                    fieldName: 'token_type',
                    value: 'v2'
                });
            }
            if (!error) {
                this.hasCaptchaError = true;
            }
        },
    },
    computed: {
        ...mapGetters({
            userInfo: 'user/getUserInfo',
            subscribeData: 'header/getSubscribeData',
        }),
        lang() {
            return this.$i18n.locale
        },
        privacyLink1() {
            return this.lang === 'ru' ? '/personal_data_accept.pdf' : '/personal_data_accept_kz.pdf'
        },
        privacyLink2() {
            return this.lang === 'ru' ? '/personal_data_policy.pdf' : '/personal_data_policy_kz.pdf'
        },
    }
}
