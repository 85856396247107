export default async function (ctx) {
    if(process.client) {
        if (ctx.route?.query?.utm_medium && ctx.route?.query?.['utm_content'] && ctx.route?.query?.['utm_source'] === 'xpartners' ) {
            setXPartners(ctx.route.query);
        } else if (Object.values(ctx.route?.query).length) {
            if (
                (ctx.route?.query?.utm_source && (ctx.route?.query?.utm_source !== 'xpartners' || ctx.route?.query?.utm_source !== 'esputnik-recommendations')) ||
                !!ctx.route?.query?.gclid ||
                !!ctx.route?.query?.uid
            ) {
                clearAffiliate(ctx.route.query)
            }
        }
        function setXPartners(query) {
            let expiresDate = new Date(( 90 * 60 * 60 * 24 * 1000) + + new Date)
            let options = {
                path: '/',
                expires: expiresDate
            }
            ctx.$cookies.set('xp_cl_id', query.utm_content, options);
            ctx.$cookies.set('affInfo', JSON.stringify(query), options);
        }
        function clearAffiliate(query) {
            ctx.$cookies.remove('xp_cl_id');
            ctx.$cookies.remove('affInfo');
        }
    }
}
