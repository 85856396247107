export default {
    // General

    SET_LANG_POPUP_SHOWING_STATE: 'SET_LANG_POPUP_SHOWING_STATE',
    SET_FB_API_KEY: 'SET_FB_API_KEY',
    SET_GOOGLE_API_KEY: 'SET_GOOGLE_API_KEY',
    SET_LANG_POINT: 'SET_LANG_POINT',



    SET_HOVERED_MENU_ITEM: 'SET_HOVERED_MENU_ITEM',
    SET_ACTIVE_MENU_ITEM_KEY: 'SET_ACTIVE_MENU_ITEM_KEY',
    SET_MENU_DATA: 'SET_MENU_DATA',
    SET_HEADER_TOP_LINKS: 'SET_HEADER_TOP_LINKS',
    SET_HEADER_COUNTS: 'SET_HEADER_COUNTS',
    SET_HEADER_FAVORITE_COUNT: 'SET_HEADER_FAVORITE_COUNT',
    SET_HEADER_CART_COUNT: 'SET_HEADER_CART_COUNT',
    SET_USP_HEADER_ITEMS: 'SET_USP_HEADER_ITEMS',
    SET_SUBSCRIBE_DATA: 'SET_SUBSCRIBE_DATA',
    SET_USP_DATA: 'SET_USP_DATA',
    SET_FOOTER_COLUMNS: 'SET_FOOTER_COLUMNS',
    SET_LANG_LINKS: 'SET_LANG_LINKS',

    SET_ACTIVE_MOBILE_MENU: 'SET_ACTIVE_MOBILE_MENU',
    SET_ACTIVE_MOBILE_MENU_CATEGORY: 'SET_ACTIVE_MOBILE_MENU_CATEGORY',
    SET_ACTIVE_MOBILE_MENU_COLUMN: 'SET_ACTIVE_MOBILE_MENU_COLUMN',

    // Search
    SET_SEARCH_GLOBAL_REQUEST: 'SET_SEARCH_GLOBAL_REQUEST',
    SET_SEARCH_HINTS: 'SET_SEARCH_HINTS',

    //Product view
    SET_PRODUCT: 'SET_PRODUCT',
    SET_PRODUCT_SIZE: 'SET_PRODUCT_SIZE',
    SET_CHOSEN_SIZE: 'SET_CHOSEN_SIZE',
    SET_FAVORITE_FLAG: 'SET_FAVORITE_FLAG',
    SET_CART_ADDED_FLAG: 'SET_CART_ADDED_FLAG',

    // Favorite
    SET_FAVORITE_LIST: 'SET_FAVORITE_LIST',
    SET_FAVORITE_COUNT: 'SET_FAVORITE_COUNT',

    //Cart
    SET_CART_LIST: 'SET_CART_LIST',
    SET_CART_INFO: 'SET_CART_INFO',
    SET_CART_TOTAL: 'SET_CART_TOTAL',
    SET_ITEM_COUNT: 'SET_ITEM_COUNT',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
    REMOVE_ITEM: 'REMOVE_ITEM',
    UPDATE_CART_TOTAL: 'UPDATE_CART_TOTAL',
    SET_PROMOCODE_INFO: 'SET_PROMOCODE_INFO',
    SET_PROMOCODE_DATA: 'SET_PROMOCODE_DATA',
    SET_ORDER_TAGS: 'SET_ORDER_TAGS',
    SET_LAST_ADDED: 'SET_LAST_ADDED',
    SET_TOTAL_PRICES: 'SET_TOTAL_PRICES',
    SET_PREORDER_ID: 'SET_PREORDER_ID',
    SET_PREORDER_INFO: 'SET_PREORDER_INFO',
    SET_POSITION_COUNT: 'SET_POSITION_COUNT',
    SET_PAY_METHODS_LIST: 'SET_PAY_METHODS_LIST',
    SET_ACTIVE_PAY_METHOD: 'SET_ACTIVE_PAY_METHOD',
    SET_PAY_DATA: 'SET_PAY_DATA',
    SET_CHECKOUT_MAX_STEP: 'SET_CHECKOUT_MAX_STEP',
    SET_CHECKOUT_CUSTOMER_ADDRESS_ID: 'SET_CHECKOUT_CUSTOMER_ADDRESS_ID',
    SET_CHECKOUT_CUSTOMER_ADDRESS_INFO: 'SET_CHECKOUT_CUSTOMER_ADDRESS_INFO',
    SET_CHECKOUT_DELIVERY_TYPE: 'SET_CHECKOUT_DELIVERY_TYPE',
    SET_CHECKOUT_DELIVERY_NAME: 'SET_CHECKOUT_DELIVERY_NAME',

    //Catalog page
    SET_SORT_RULES: 'SET_SORT_RULES',
    SET_FILTER_LIST: 'SET_FILTER_LIST',
    SET_APPLIED_LIST: 'SET_APPLIED_LIST',
    SET_ACTIVE_SORT: 'SET_ACTIVE_SORT',

    //Dynamic page
    SET_ROUTE_INFO: 'SET_ROUTE_INFO',
    SET_MICRO_STRUCTURE: 'SET_MICRO_STRUCTURE',
    SET_MACRO_STRUCTURE: 'SET_MACRO_STRUCTURE',
    CLEAR_PAGE_INFO: 'CLEAR_PAGE_INFO',
    SET_DYNAMIC_PAGE_LOADING: 'SET_DYNAMIC_PAGE_LOADING',
    SET_WIDGETS_DATA_LOADING: 'SET_WIDGETS_DATA_LOADING',
    // CATALOG

    SET_CATALOG_DATA : 'SET_CATALOG_DATA',
    ADD_OPENED_FILTER : 'ADD_OPENED_FILTER',
    REMOVED_OPENED_FILTER : 'REMOVED_OPENED_FILTER',
    REMOVED_ALL_OPENED_FILTER : 'REMOVED_ALL_OPENED_FILTER',
    ADD_NEW_FILTER_TO_HISTORY : 'ADD_NEW_FILTER_TO_HISTORY',
    REMOVE_FILTER_HISTORY_ITEM : 'REMOVE_FILTER_HISTORY_ITEM',
    CLEAR_FILTER_HISTORY : 'CLEAR_FILTER_HISTORY',
    CHANGE_ALL_FILTERS_SHOWED_STATE : 'CHANGE_ALL_FILTERS_SHOWED_STATE',
    CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE : 'CHANGE_MOBILE_FILTERS_POPUP_SHOWED_STATE',
    TOGGLE_SELECT_STATE_FOR_FILTER : 'TOGGLE_SELECT_STATE_FOR_FILTER',

    // Storefinder
    SET_STORE_LIST: 'SET_STORE_LIST',
    CLEAR_STORE_LIST: 'CLEAR_STORE_LIST',
    SET_ACTIVE_STORE: 'SET_ACTIVE_STORE',
    SET_STORES_PAGE_INFO: 'SET_STORES_PAGE_INFO',

    // User
    SET_USER_DATA: 'SET_USER_DATA',
    SET_LOAD_USER_DATA_FLAG: 'SET_LOAD_USER_DATA_FLAG',
    SET_SUBSCRIBE_FLAG: 'SET_SUBSCRIBE_FLAG',
    SET_ADIDAS_UNIVERSE: 'SET_ADIDAS_UNIVERSE',
    SET_COUPONS: 'SET_COUPONS',
    SET_LAST_ORDER: 'SET_LAST_ORDER',
    CLEAR_USER_INFO: 'CLEAR_USER_INFO',
    SET_LOGIN_METHOD: 'SET_LOGIN_METHOD',
    SET_ORDER_TRACKER: 'SET_ORDER_TRACKER',
    LOAD_ORDER_HISTORY: 'LOAD_ORDER_HISTORY',
    CLEAR_ORDER_HISTORY: 'CLEAR_ORDER_HISTORY',
    SET_TALK_ME_STATUS: 'SET_TALK_ME_STATUS',

    //forms

    ADD_FORM_TO_LIST: 'ADD_FORM_TO_LIST',
    REMOVE_FORM_LIST: 'REMOVE_FORM_LIST',
    ADD_FIELD_TO_FORM: 'ADD_FIELD_TO_FORM',
    UPDATE_FORM_ERROR_BY_ID: 'UPDATE_FORM_ERROR_BY_ID',
    UPDATE_FORM_FIELDS_BY_ID: 'UPDATE_FORM_FIELDS_BY_ID',
    UPDATE_FORM_FIELD_BY_ID: 'UPDATE_FORM_FIELD_BY_ID',
    UPDATE_FORM_VALUE_BY_ID: 'UPDATE_FORM_VALUE_BY_ID',
    CLEAR_ALL_FIELDS: 'CLEAR_ALL_FIELDS',


    SAVE_CODE_FOR_REGISTER: 'SAVE_CODE_FOR_REGISTER',
    CLEAR_SAVED_CODE_FOR_REGISTER: 'CLEAR_SAVED_CODE_FOR_REGISTER'

}
