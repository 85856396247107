import dsSdk from 'omnicore-ds2-sdk';
import sdkRequestMethods from "../../sdkRequestMethods";

export default {
    data: function () {
        return {
            sdkList: {
                'delivery': new dsSdk({
                    lang: this.$i18n.locale,
                    baseUrl: `${API.main}/delivery`,
                    auth: API.auth,
                    headers: {
                        'X-Api-Key': this.$cookies.get('xApiKey') ?? '',
                    },
                }),
            },
            sdkRequestMethods: sdkRequestMethods,
        }

    },
    computed: {
        loadingDataNow: {
            get: function () {
                return this.row.data.loading
            }, set: function (val) {
                this.setNewRowData(Object.assign({}, this.newRowData || this.row, {
                    data: Object.assign({}, (this.newRowData || this.row).data, {
                        loading: val
                    })
                }));
            }
        },
        hasDataLoadSettings() {
            return !!(_get(this,'row.dataLoad', false))
        },
        alwaysCancelPreviousRequest() {
            return this.hasDataLoadSettings && this.row.dataLoad.hasOwnProperty('cancelPreviousRequest');
        },
        needDataLoad() {
            return this.useUrlDataLoad || this.useSdkForDataLoad;
        },

        useUrlDataLoad() {
            return this.hasDataLoadSettings && this.row.dataLoad.hasOwnProperty('byUrl') && this.row.dataLoad.byUrl.hasOwnProperty('baseUrl');
        },

        // sdk info
        useSdkForDataLoad() {
            return this.hasDataLoadSettings && this.row.dataLoad.hasOwnProperty('sdk') && this.row.dataLoad.sdk.hasOwnProperty('type') && this.row.dataLoad.sdk.hasOwnProperty('request') && this.sdkList.hasOwnProperty(this.row.dataLoad.sdk.type)
        },

        sdkRequestTypeMethods() {
            if (!this.useSdkForDataLoad) {
                return null;
            }
            return this.sdkRequestMethods[this.row.dataLoad.sdk.type].hasOwnProperty(this.row.dataLoad.sdk.request) ? this.sdkRequestMethods[this.row.dataLoad.sdk.type][this.row.dataLoad.sdk.request] : [];
        },
        paramsForSdkRequest() {
            if (!this.useSdkForDataLoad || !this.row.dataLoad.sdk.hasOwnProperty('params')) {
                return {}
            }
            const paramsList = {};
            for (let key in this.row.dataLoad.sdk.params) {
                if (this.row.dataLoad.sdk.params[key] === this.row.name && !this.filled) {
                    continue;
                }
                const val = this.paramsForDataLoadValues[this.row.dataLoad.sdk.params[key]] || '';
                val && (paramsList[key] = val);
            }
            return paramsList;
        },
        paramsForUrlRequest(){
            if (!this.useUrlDataLoad || !this.row.dataLoad.byUrl.hasOwnProperty('params')) {
                return {}
            }
            const paramsList = {};
            for (let key in this.row.dataLoad.byUrl.params) {
                if (this.row.dataLoad.byUrl.params[key] === this.row.name && !this.filled) {
                    continue;
                }
                const val = this.paramsForDataLoadValues[this.row.dataLoad.byUrl.params[key]] || '';
                val && (paramsList[key] = val)
            }
            return paramsList;
        },
        searchParamName() {
            if (!this.needDataLoad) {
                return '';
            }
            return this.useSdkForDataLoad ? (this.row.dataLoad.sdk.searchParam || '') : (this.row.dataLoad.byUrl.hasOwnProperty('searchParam') ? this.row.dataLoad.byUrl.searchParam : '');
        },

        sdkParamsDataLoadNameList: function () {
            return this.useSdkForDataLoad ? this.getParamsPromList(this.row.dataLoad.sdk) : [];
        },
        byUrlParamsDataLoadNameList: function () {
            return this.useUrlDataLoad ? this.getParamsPromList(this.row.dataLoad.byUrl) : [];
        },
        paramsForDataLoadNameList: function () {
            return this.byUrlParamsDataLoadNameList.concat(this.sdkParamsDataLoadNameList)
        },
        paramsForDataLoadValues: function () {
            const values = {};
            this.paramsForDataLoadNameList.forEach(function (name) {
                values[name] = this.keyValueFormData[name]
            }.bind(this));
            return values;
        },
        loadData: function () {
            return _debounce(this.loadAllData, 50)
        },
    },
    watch: {
        paramsForDataLoadValues: {
            handler: function (newVal, oldVal) {
                if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.loadData()
                }
            },
            immediate: true
        }
    },
    methods: {
        getParamsPromList(paramsList) {
            const list = [];
            if (paramsList.hasOwnProperty('params')) {
                for (let key in paramsList.params) {
                    if (paramsList[key] === this.rowName) {
                        continue;
                    }
                    list.push(paramsList.params[key]);
                }
            }
            return list

        },
        loadAllData(params = {}, force = false) {
            setTimeout(()=>{
                if (this.useSdkForDataLoad) {
                    this.sdkDataLoad(params, force)
                } else {
                    this.routeDataLoad(params, force)
                }
            })
        },
        routeDataLoad(params = {}, force) {
            const dontLoad = !this.needDataLoad || !this.useUrlDataLoad || !this.showRow || !this.loadingDataNow;
            if (dontLoad && !this.cancelPreviousRequest && !force) {
                return;
            }
            const additionRequestParams = {};
            params.hasOwnProperty('page') && (additionRequestParams.page = params.page);
            params.hasOwnProperty('search') && (additionRequestParams[this.searchParamName] = params.search);

            const requestType = this.row.dataLoad.byUrl.method || 'get';
            const requestParam = Object.assign({},additionRequestParams, this.paramsForUrlRequest);
            axios({
                method: requestType,
                url: this.row.dataLoad.byUrl.baseUrl,
                [requestType==='get'? 'params':'data']:  requestParam,
                cancelToken: this.cancelSource.token
            }).then(function (response) {
                const data =  response.data
                const notFirstPageLoaded = (params.page && params.page > 1);
                const newListData = notFirstPageLoaded ? [].concat((this.newRowData || this.row).data.items).concat(data) : data;

                this.newRowData = Object.assign({}, this.newRowData || this.row, {
                    data: {
                        items: newListData,
                        pagination: {
                            page: params.page || 1,
                            hasNext: data.pagination.hasNext
                        },
                        loading: false
                    }
                });
                if (this.filled) {
                    newListData.forEach(function (item) {
                        if (item[this.valueProp] == this._value) {
                            this.newRowData.selectedValue = JSON.parse(JSON.stringify(item))
                        }
                    }.bind(this))
                }

                this.selectedValueUpdate(this.newRowData.selectedValue);
                this.filled && this.validateAfterDataLoad && this.canBeValidatedOnStart && this.validate(true)
                this.validateAfterDataLoad = false
            }.bind(this)).catch(function (error) {
                this.newRowData = Object.assign({}, this.newRowData || this.row, {
                    data: {
                        items: [],
                        pagination: {
                            page: 1,
                            hasNext: false
                        },
                        loading: false
                    }
                });
                this.selectedValueUpdate(this.newRowData.selectedValue);

            }.bind(this))
        },
        sdkDataLoad(params = {}, force) {
            const dontLoad = !this.useSdkForDataLoad || !this.needDataLoad || (!this.showRow && !this.isHidden) || this.loadingDataNow || !(this.sdkRequestTypeMethods.hasOwnProperty('default'));
            if (dontLoad && !this.cancelPreviousRequest && !force) {
                return;
            }
            if (this.sdkRequest) {
                this.sdkRequest.cancel();
            }
            this.loadingDataNow = true;
            const curType = this.sdkRequestTypeMethods[this.filled && (!params.search || params.search === '') ? 'withSelected' : 'default'];
            this.sdkRequest = this.sdkList[this.row.dataLoad.sdk.type][curType.name]();
            const additionRequestParams = {};
            params.hasOwnProperty('page') && params.page > 1 && (additionRequestParams.page = params.page);
            params.hasOwnProperty('search') && params.search !== '' && (additionRequestParams[this.searchParamName] = params.search);
            for (let paramName in Object.assign({}, this.paramsForSdkRequest, additionRequestParams, curType.additionParams)) {
                const value = this.paramsForSdkRequest[paramName] ||
                    additionRequestParams[paramName] ||
                    curType.additionParams[paramName]
                if (this.sdkRequest.__proto__.hasOwnProperty(paramName)) {
                    this.sdkRequest[paramName](value)
                } else {
                    this.sdkRequest.useParams({
                        [paramName]: value
                    })
                }
            }
            this.sdkRequest.get().then(function (data) {

                const loadedListData = data.items.map(function (el) {
                    switch (this.row.dataLoad.sdk.request) {
                        case "city":
                            el.label = `${el.type} ${el.title} `;
                            el.additionalLabel = `${el.region} ${this.$t('common.region')}`;
                            break;
                        case "type":
                            el.label = `${el.title}`;
                            break;
                        case "street":
                            el.label = `${el.type} ${el.title}`;
                            break;
                        case "warehouse":
                            el.label = `${this.$t('common.warehouse')} ${el.number}`;
                            el.selectedlabel = `${this.$t('common.warehouse')} ${el.number}`;
                            el.additionalLabel = el.title;
                            break;
                        default:
                            el.label = `${el.title}`;
                            break;
                    }
                    return Object.assign(el, {
                        id: el.id || el.uuid || el.ref || el[this.valueProp],
                        title: el.title || el.name
                    })
                }.bind(this));

                const notFirstPageLoaded = (params.page && params.page > 1);
                const newListData = notFirstPageLoaded ? [].concat((this.newRowData || this.row).data.items).concat(loadedListData) : loadedListData;

                this.newRowData = Object.assign({}, this.newRowData || this.row, {
                    data: {
                        items: newListData,
                        pagination: {
                            page: params.page || 1,
                            hasNext: data.pagination.hasNext
                        },
                        loading: false
                    }
                });

                if (this.filled) {
                    newListData.forEach(function (item) {
                        if (item[this.valueProp] == this._value) {
                            this.newRowData.selectedValue = JSON.parse(JSON.stringify(item))
                        }
                    }.bind(this))


                }
                this.selectedValueUpdate(this.newRowData.selectedValue);

            }.bind(this)).catch(function (error) {
                this.newRowData = Object.assign({}, this.newRowData || this.row, {
                    data: {
                        items: [],
                        pagination: {
                            page: 1,
                            hasNext: false
                        },
                        loading: false
                    }
                });
                this.selectedValueUpdate(this.newRowData.selectedValue);

            }.bind(this)).finally(() => {
                this.filled && this.validateAfterDataLoad && this.canBeValidatedOnStart && this.validate(true)
                this.validateAfterDataLoad = false
            })
        },
        search(params) {
            this.loadAllData(params, true)
        }
    },

    created() {
        if (this.needDataLoad && this.showRow && !this.loadingDataNow) {
            this.filled && (this.validateAfterDataLoad = true)
            this.loadAllData()
        } else {
            this.filled && this.canBeValidatedOnStart &&  this.validate(true)
        }
    }
}
